import Address from "./address";

export default class Petitioner {
    public role: string = '';
    public companyRegNumber: string = '';
    public name: string = '';
    public regNumber: string = '';
    public recordOffice: string = '';
    public address: Address = new Address();
    public email: string = '';
    public caseNumber: string = '';
    public administrator: string = '';
    public administratorPhoneNumber: string = '';
}
