import CgtFormData from "../common/dto/cgt-form-data";
import http from "../plugins/http";
import {CompanyDataResponse} from "../common/dto/company-data-response";
import {AxiosResponse} from "axios";
import CompanyNameResponse from "../common/dto/company-name-response";
import CityResponse from "../common/dto/city-response";

export default class CgtFormService {

    /*eslint-disable */
    public static readonly REGEXES = {
        postalCode: /^.{1,20}$/,
        city: /^.{1,64}$/,
        district: /^.{0,64}$/,
        area: /^.{0,64}$/,
        areaType: /^.{0,32}$/,
        houseNumber: /^.{0,50}$/,
        house: /^.{0,16}$/,
        stairway: /^.{0,16}$/,
        floor: /^.{0,16}$/,
        door: /^.{0,16}$/,
        companyRegNumber: /^((?:0[1-9])|(?:1[0-9])|(?:20))-((?:0[1-9])|(?:1[0-9])|(?:2[0-3]))-\d{6}$/,
        email: /^[-a-zA-Z0-9_.]+@([-a-zA-Z0-9]+\.)+[a-zA-Z0-9]+$/,
        // tslint:disable-next-line:max-line-length
        multilineText: /^[\r\n\t 0-9A-Za-zÁÉÍÓÖŐÚÜŰáéíóöőúüű!"&'<>()*+.\/,:;@?#$%=\[\]_{|}^~§äÄß-]*$/,
        // tslint:disable-next-line:max-line-length
        singlelineText: /^[0-9A-Za-zÁÉÍÓÖŐÚÜŰáéíóöőúüű!"&'<>()*+./,:;@?#$%=\[\]_{|}^~§äÄß-][ 0-9A-Za-zÁÉÍÓÖŐÚÜŰáéíóöőúüű!"&'<>()*+./,:;@?#$%=\[\]_{|}^~§äÄß-]*$/,
        countryCode: /^[A-Z]{2}$/,
        cgtCaseNumber: /^\d{2}-\d{2}-\d{6}$/
    };

    /*eslint-enable */

    public checkForbiddenCharacter(text: string) {
        return !text || text.length == 0 || CgtFormService.REGEXES.singlelineText.test(text) || 'cgt-form.validations.forbidden-character';
    }

    public checkRequired(text: string) {
        return (text && text.length > 0) || 'cgt-form.validations.required';
    }

    public checkCaseNumber(caseNumber: string) {
        return CgtFormService.REGEXES.cgtCaseNumber.test(caseNumber) || 'cgt-form.validations.wrong-format';
    }

    public checkCompanyRegistrationNumber(companyRegistrationNumber: string) {
        return CgtFormService.REGEXES.companyRegNumber.test(companyRegistrationNumber) || 'cgt-form.validations.wrong-format';
    }

    public checkEmail(email: string) {
        return CgtFormService.REGEXES.email.test(email) || 'cgt-form.validations.email';
    }

    public checkMultiline(text: string) {
        return !text || text.length == 0 || CgtFormService.REGEXES.multilineText.test(text) || 'cgt-form.validations.forbidden-character';
    }

    public generate(cgtFormData: CgtFormData) {
        return http.post(`/api/cgt/xml-generator`, cgtFormData)
    }

    public retrieveCompanyName(companyRegNumber: string, captchaToken: string): Promise<AxiosResponse<CompanyNameResponse>> {
        return http.get<CompanyNameResponse>(`/api/cgt/company-name`, {params: {cegjegyzekszam: companyRegNumber, captchaToken: captchaToken}});
    }

    public retrieveCompanyNameAndAddress(companyRegNumber: string, captchaToken: string): Promise<AxiosResponse<CompanyDataResponse>> {
        return http.get<CompanyDataResponse>(`/api/cgt/company-data`, {params: {cegjegyzekszam: companyRegNumber, captchaToken: captchaToken}});
    }

    public retrieveCitiesByPostalCode(postalCode: string): Promise<AxiosResponse<CityResponse>> {
        return http.get<CityResponse>(`/api/cgt/postal-code/${postalCode}`);
    }

    public isAllRequiredFieldFilled(formData: CgtFormData, isNewCgtCase: boolean, isCompany: boolean, isLegalRepresentative: boolean) {
        if (isNewCgtCase) {
            if (!formData.companyRegNumber || !formData.name) {
                return false;
            }
        } else {
            if (!formData.cgtCaseNumber) {
                return false;
            }
        }

        if (isCompany) {
            if (!formData.petitioner.companyRegNumber) {
                return false;
            }
        } else {
            if (!formData.petitioner.name) {
                return false;
            }
        }

        const petitionerAddress = formData.petitioner.address;
        if (!petitionerAddress.countryCode || !petitionerAddress.postalCode || !petitionerAddress.city) {
            return false;
        }

        if (isLegalRepresentative) {
            const legalRepresentative = formData.legalRepresentative;
            const address = legalRepresentative.address;
            if (!legalRepresentative.name || !legalRepresentative.associationName || !legalRepresentative.associationNumber || !address.countryCode || !address.postalCode || !address.city) {
                return false;
            }
        } else {
            if (!formData.petitioner.email) {
                return false;
            }
        }

        return true;
    }
}
