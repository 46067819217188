import Petitioner from "./petitioner";
import LegalRepresentative from "./legal-representative";

export default class CgtFormData {
    public cgtCaseNumber: string = '';
    public companyRegNumber: string = '';
    public name: string = '';
    public petitioner: Petitioner = new Petitioner();
    public legalRepresentative: LegalRepresentative = new LegalRepresentative();
    public statement: string = '';
}
