
import Vue from 'vue';
import {Component, Prop, Watch} from 'vue-property-decorator';
import Address from "../common/dto/address";
import {countries} from "../common/countries";
import CgtFormService from "../service/cgt-form-service";

@Component({})
export default class CgtAddress extends Vue {

    @Prop() public value?: Address;
    @Prop() public validationNeeded?: boolean;
    @Prop({default: false}) public disabled?: boolean;

    public cgtFormService = new CgtFormService();

    public cgtAddress: Address = new Address();

    public countries = countries;
    public cityItems: string[] = [];
    public mask = '####';

    //region Validation
    private isValidCountry: boolean = true;
    private countryErrorMessage: string = "";

    private isValidPostalCode: boolean = true;
    private postalCodeErrorMessage: string = "";

    private isValidCity: boolean = true;
    private cityErrorMessage: string = "";

    private isValidDistrict: boolean = true;
    private districtErrorMessage: string = "";

    private isValidAreaName: boolean = true;
    private areaNameErrorMessage: string = "";

    private isValidAreaType: boolean = true;
    private areaTypeErrorMessage: string = "";

    private isValidStreetNumber: boolean = true;
    private streetNumberErrorMessage: string = "";

    private isValidBuilding: boolean = true;
    private buildingErrorMessage: string = "";

    private isValidStairway: boolean = true;
    private stairwayErrorMessage: string = "";

    private isValidFloor: boolean = true;
    private floorErrorMessage: string = "";

    private isValidDoor: boolean = true;
    private doorErrorMessage: string = "";

    private validateCountry() {
        const countryCode = this.cgtAddress.countryCode;
        const requiredResult = this.cgtFormService.checkRequired(countryCode);
        if (requiredResult !== true) {
            this.isValidCountry = false;
            this.countryErrorMessage = this.$t(requiredResult).toString();
        } else {
            if (!CgtFormService.REGEXES.countryCode.test(countryCode)) {
                this.isValidCountry = false;
                this.countryErrorMessage = this.$t('cgt-form.validations.address.country-code-error').toString();
            } else {
                this.isValidCountry = true;
                this.countryErrorMessage = "";
            }
        }
    }

    private validatePostalCode() {
        const countryCode = this.cgtAddress.countryCode;
        const postalCode = this.cgtAddress.postalCode;
        const requiredResult = this.cgtFormService.checkRequired(postalCode);
        if (requiredResult !== true) {
            this.isValidPostalCode = false;
            this.postalCodeErrorMessage = this.$t(requiredResult).toString();
        } else {
            const forbiddenCharacterResult = this.cgtFormService.checkForbiddenCharacter(postalCode);
            if (forbiddenCharacterResult !== true) {
                this.isValidPostalCode = false;
                this.postalCodeErrorMessage = this.$t(forbiddenCharacterResult).toString();
            } else {
                if (!CgtFormService.REGEXES.postalCode.test(postalCode)) {
                    this.isValidPostalCode = false;
                    this.postalCodeErrorMessage = this.$t('cgt-form.validations.address.postal-code-error').toString();
                } else {
                    this.isValidPostalCode = true;
                    this.postalCodeErrorMessage = "";
                }
            }
        }
    }

    private validateCity() {
        const city = this.cgtAddress.city;
        const requiredResult = this.cgtFormService.checkRequired(city);
        if (requiredResult !== true) {
            this.isValidCity = false;
            this.cityErrorMessage = this.$t(requiredResult).toString();
        } else {
            const forbiddenCharacterResult = this.cgtFormService.checkForbiddenCharacter(city);
            if (forbiddenCharacterResult !== true) {
                this.isValidCity = false;
                this.cityErrorMessage = this.$t(forbiddenCharacterResult).toString();
            } else {
                if (!CgtFormService.REGEXES.city.test(city)) {
                    this.isValidCity = false;
                    this.cityErrorMessage = this.$t('cgt-form.validations.address.city-error').toString();
                } else {
                    this.isValidCity = true;
                    this.cityErrorMessage = "";
                }
            }
        }
    }

    private validateDistrict() {
        const district = this.cgtAddress.district;
        const forbiddenCharacterResult = this.cgtFormService.checkForbiddenCharacter(district);
        if (forbiddenCharacterResult !== true) {
            this.isValidDistrict = false;
            this.districtErrorMessage = this.$t(forbiddenCharacterResult).toString();
        } else {
            if (!CgtFormService.REGEXES.district.test(district)) {
                this.isValidDistrict = false;
                this.districtErrorMessage = this.$t('cgt-form.validations.address.district-error').toString();
            } else {
                this.isValidDistrict = true;
                this.districtErrorMessage = "";
            }
        }
    }

    private validateAreaName() {
        const areaName = this.cgtAddress.areaName;
        const forbiddenCharacterResult = this.cgtFormService.checkForbiddenCharacter(areaName);
        if (forbiddenCharacterResult !== true) {
            this.isValidAreaName = false;
            this.areaNameErrorMessage = this.$t(forbiddenCharacterResult).toString();
        } else {
            if (!CgtFormService.REGEXES.area.test(areaName)) {
                this.isValidAreaName = false;
                this.areaNameErrorMessage = this.$t('cgt-form.validations.address.area-name-error').toString();
            } else {
                this.isValidAreaName = true;
                this.areaNameErrorMessage = "";
            }
        }
    }

    private validateAreaType() {
        const areaType = this.cgtAddress.areaType;
        const forbiddenCharacterResult = this.cgtFormService.checkForbiddenCharacter(areaType);
        if (forbiddenCharacterResult !== true) {
            this.isValidAreaType = false;
            this.areaTypeErrorMessage = this.$t(forbiddenCharacterResult).toString();
        } else {
            if (!CgtFormService.REGEXES.areaType.test(areaType)) {
                this.isValidAreaType = false;
                this.areaTypeErrorMessage = this.$t('cgt-form.validations.address.area-type-error').toString();
            } else {
                this.isValidAreaType = true;
                this.areaTypeErrorMessage = "";
            }
        }
    }

    private validateStreetNumber() {
        const streetNumber = this.cgtAddress.streetNumber;
        const forbiddenCharacterResult = this.cgtFormService.checkForbiddenCharacter(streetNumber);
        if (forbiddenCharacterResult !== true) {
            this.isValidStreetNumber = false;
            this.streetNumberErrorMessage = this.$t(forbiddenCharacterResult).toString();
        } else {
            if (!CgtFormService.REGEXES.houseNumber.test(streetNumber)) {
                this.isValidStreetNumber = false;
                this.streetNumberErrorMessage = this.$t('cgt-form.validations.address.street-number-error').toString();
            } else {
                this.isValidStreetNumber = true;
                this.streetNumberErrorMessage = "";
            }
        }
    }

    private validateBuilding() {
        const building = this.cgtAddress.building;
        const forbiddenCharacterResult = this.cgtFormService.checkForbiddenCharacter(building);
        if (forbiddenCharacterResult !== true) {
            this.isValidBuilding = false;
            this.buildingErrorMessage = this.$t(forbiddenCharacterResult).toString();
        } else {
            if (!CgtFormService.REGEXES.house.test(building)) {
                this.isValidBuilding = false;
                this.buildingErrorMessage = this.$t('cgt-form.validations.address.building-error').toString();
            } else {
                this.isValidBuilding = true;
                this.buildingErrorMessage = "";
            }
        }
    }

    private validateStairway() {
        const stairway = this.cgtAddress.stairway;
        const forbiddenCharacterResult = this.cgtFormService.checkForbiddenCharacter(stairway);
        if (forbiddenCharacterResult !== true) {
            this.isValidStairway = false;
            this.stairwayErrorMessage = this.$t(forbiddenCharacterResult).toString();
        } else {
            if (!CgtFormService.REGEXES.stairway.test(stairway)) {
                this.isValidStairway = false;
                this.stairwayErrorMessage = this.$t('cgt-form.validations.address.stairway-error').toString();
            } else {
                this.isValidStairway = true;
                this.stairwayErrorMessage = "";
            }
        }
    }

    private validateFloor() {
        const floor = this.cgtAddress.floor;
        const forbiddenCharacterResult = this.cgtFormService.checkForbiddenCharacter(floor);
        if (forbiddenCharacterResult !== true) {
            this.isValidFloor = false;
            this.floorErrorMessage = this.$t(forbiddenCharacterResult).toString();
        } else {
            if (!CgtFormService.REGEXES.floor.test(floor)) {
                this.isValidFloor = false;
                this.floorErrorMessage = this.$t('cgt-form.validations.address.floor-error').toString();
            } else {
                this.isValidFloor = true;
                this.floorErrorMessage = "";
            }
        }
    }

    private validateDoor() {
        const door = this.cgtAddress.door;
        const forbiddenCharacterResult = this.cgtFormService.checkForbiddenCharacter(door);
        if (forbiddenCharacterResult !== true) {
            this.isValidDoor = false;
            this.doorErrorMessage = this.$t(forbiddenCharacterResult).toString();
        } else {
            if (!CgtFormService.REGEXES.door.test(door)) {
                this.isValidDoor = false;
                this.doorErrorMessage = this.$t('cgt-form.validations.address.door-error').toString();
            } else {
                this.isValidDoor = true;
                this.doorErrorMessage = "";
            }
        }
    }

    //endregion

    //region Change handlers
    private countryChanged() {
        this.validateCountry();
        this.emitAddressChanged();
    }

    private postalCodeChanged() {
        this.validatePostalCode();
        this.retrieveCities(this.cgtAddress.postalCode);
        this.emitAddressChanged();
    }

    private cityChanged() {
        this.validateCity();
        this.emitAddressChanged();
    }

    private districtChanged() {
        this.validateDistrict();
        this.emitAddressChanged();
    }

    private areaNameChanged() {
        this.validateAreaName();
        this.emitAddressChanged();
    }

    private areaTypeChanged() {
        this.validateAreaType();
        this.emitAddressChanged();
    }

    private streetNumberChanged() {
        this.validateStreetNumber();
        this.emitAddressChanged();
    }

    private buildingChanged() {
        this.validateBuilding();
        this.emitAddressChanged();
    }

    private stairwayChanged() {
        this.validateStairway();
        this.emitAddressChanged();
    }

    private floorChanged() {
        this.validateFloor();
        this.emitAddressChanged();
    }

    private doorChanged() {
        this.validateDoor();
        this.emitAddressChanged();
    }

    //endregion

    private retrieveCities(postalCode: string) {
        if (postalCode.length === 4) {
            this.cgtFormService.retrieveCitiesByPostalCode(postalCode).then(data => {
                    this.cityItems = data.data.cities;
                    if (this.cityItems.length === 1 && this.cgtAddress) {
                        this.cgtAddress.city = this.cityItems[0];
                        this.emitAddressChanged();
                    }
                }
            )
        }
    }

    public emitAddressChanged() {
        const addressIsValid = this.isValidCountry && this.isValidPostalCode && this.isValidCity && this.isValidDistrict && this.isValidAreaName && this.isValidAreaType && this.isValidStreetNumber && this.isValidBuilding && this.isValidStairway && this.isValidFloor && this.isValidDoor;
        this.$emit('addressChanged', addressIsValid);
    }

    private validateFields() {
        this.validateCountry();
        this.validatePostalCode();
        this.validateCity();
        this.validateDistrict();
        this.validateAreaName();
        this.validateAreaType();
        this.validateStreetNumber();
        this.validateBuilding();
        this.validateStairway();
        this.validateFloor();
        this.validateDoor();

        this.emitAddressChanged();
    }

    public mounted() {
        this.setAddress();
    }

    @Watch('validationNeeded')
    public watchValidation() {
        if (this.validationNeeded) {
            this.validateFields();
        }
    }

    @Watch('cgtAddress.city')
    public watchCityChanges() {
        this.cityChanged();
    }

    @Watch('value')
    public setAddress() {
        if (this.value) {
            this.cgtAddress = this.value;
        }
        if (this.cgtAddress) {
            this.cgtAddress.countryCode = 'HU';
            if (this.disabled) {
                this.cityItems = [this.cgtAddress.city];
            }
        }
    }

}
