
import Vue from 'vue';
import {Component, Watch} from 'vue-property-decorator';
import CustomPageTitle from "../components/CustomPageTitle.vue";
import CgtAddress from '@/components/CgtAddress.vue';
import {AdditionalCgtForm} from "../common/enum/additional-cgt-form";
import {PetitionerRole} from "../common/enum/petitioner-role";
import CgtFormData from "../common/dto/cgt-form-data";
import Recaptcha from "../components/Recaptcha.vue";
import CgtFormService from "../service/cgt-form-service";
import Address from "../common/dto/address";
import LegalRepresentative from "../common/dto/legal-representative";

@Component({
    components: {
        CgtAddress,
        CustomPageTitle,
        Recaptcha
    },
})
export default class CgtForm extends Vue {
    public cgtFormService = new CgtFormService();
    public roleItems = [
        {text: 'cgt-form.company-in-proceeding', value: PetitionerRole.ELJARAS_ALA_VONT_CEG},
        {text: 'cgt-form.petitioner-in-proceeding', value: PetitionerRole.KERELMEZO},
        {text: 'cgt-form.other-in-proceeding', value: PetitionerRole.EGYEB},
    ];
    public tabs = [
        {text: 'cgt-form.legal-representative', id: 0},
        {text: 'cgt-form.petitioner-misc-data', id: 1},
    ];
    public activeTab: number = 0;
    public selectedForm: AdditionalCgtForm = AdditionalCgtForm.JOGI;
    public formData: CgtFormData = new CgtFormData();
    public isNewCgtCase: boolean = true;
    public isCompany: boolean = true;
    public isCaptchaVisibleForName: boolean = false;
    public isCaptchaVisibleForData: boolean = false;
    public formerCompanyRegNumber: string = '';
    public formerPetitionerCompanyRegNumber: string = '';
    public mask = "##-##-######"

    //region Validation
    public isValidForm: boolean = false;
    public petitionerAddressValidationNeeded: boolean = false;
    public legalRepresentativeAddressValidationNeeded: boolean = false;

    private isValidCgtCaseNumber: boolean = true;
    private cgtCaseNumberErrorMessage: string = "";

    private isValidCompanyRegistrationNumber: boolean = true;
    private companyRegistrationNumberErrorMessage: string = "";

    private isValidCompanyName: boolean = true;
    private companyNameErrorMessage: string = "";

    private isValidPetitionerCompanyRegistrationNumber: boolean = true;
    private petitionerCompanyRegistrationNumberErrorMessage: string = "";

    private isValidPetitionerRegistrationNumber: boolean = true;
    private petitionerRegistrationNumberErrorMessage: string = "";

    private isValidPetitionerRecordOffice: boolean = true;
    private petitionerRecordOfficeErrorMessage: string = "";

    private isValidPetitionerName: boolean = true;
    private petitionerNameErrorMessage: string = "";

    private isValidPetitionerAddress: boolean = false;

    private isValidLegalRepresentativeName: boolean = true;
    private legalRepresentativeNameErrorMessage: string = "";

    private isValidLegalRepresentativeAssociationName: boolean = true;
    private legalRepresentativeAssociationNameErrorMessage: string = "";

    private isValidLegalRepresentativeAssociationNumber: boolean = true;
    private legalRepresentativeAssociationNumberErrorMessage: string = "";

    private isValidLegalRepresentativeAddress: boolean = false;

    private isValidLegalRepresentativeEmail: boolean = true;
    private legalRepresentativeEmailErrorMessage: string = "";

    private isValidLegalRepresentativePhoneNumber: boolean = true;
    private legalRepresentativePhoneNumber: string = "";

    private isValidPetitionerEmail: boolean = true;
    private petitionerEmailErrorMessage: string = "";

    private isValidPetitionerCaseNumber: boolean = true;
    private petitionerCaseNumberErrorMessage: string = "";

    private isValidPetitionerAdministrator: boolean = true;
    private petitionerAdministratorErrorMessage: string = "";

    private isValidPetitionerAdministratorPhoneNumber: boolean = true;
    private petitionerAdministratorPhoneNumberErrorMessage: string = "";

    private isValidStatement: boolean = true;
    private statementErrorMessage: string = "";

    private updateFormValidity() {
        this.isValidForm = this.checkCgtData() && this.checkPetitionerData() && this.checkLegalRepresentativeOrPetitionerData() && this.checkRequiredData()
            && this.cgtFormService.isAllRequiredFieldFilled(this.formData, this.isNewCgtCase, this.isCompany, this.isLegalRepresentativeSelected());
    }

    private checkCgtData() {
        if (this.isNewCgtCase) {
            return this.isValidCompanyRegistrationNumber && this.isValidCompanyName;
        } else {
            return this.isValidCgtCaseNumber;
        }
    }

    private checkPetitionerData() {
        if (this.isCompany) {
            return this.isValidPetitionerCompanyRegistrationNumber;
        } else {
            return this.isValidPetitionerRegistrationNumber && this.isValidPetitionerRecordOffice;
        }
    }

    private checkLegalRepresentativeOrPetitionerData() {
        if (this.isLegalRepresentativeSelected()) {
            return this.isValidLegalRepresentativeName && this.isValidLegalRepresentativeAssociationName && this.isValidLegalRepresentativeAssociationNumber && this.isValidLegalRepresentativeAddress;
        } else {
            return this.isValidPetitionerEmail && this.isValidPetitionerCaseNumber && this.isValidPetitionerAdministrator && this.isValidPetitionerAdministratorPhoneNumber;
        }
    }

    private checkRequiredData() {
        return this.isValidCompanyRegistrationNumber && this.isValidCompanyName && this.isValidPetitionerName && this.isValidStatement && this.isValidPetitionerAddress;
    }

    private validateCgtCaseNumber() {
        const requiredResult = this.cgtFormService.checkRequired(this.formData.cgtCaseNumber);
        if (requiredResult !== true) {
            this.isValidCgtCaseNumber = false;
            this.companyRegistrationNumberErrorMessage = this.$t(requiredResult).toString();
        } else {
            const caseNumberResult = this.cgtFormService.checkCaseNumber(this.formData.cgtCaseNumber);
            if (caseNumberResult !== true) {
                this.isValidCgtCaseNumber = false;
                this.companyRegistrationNumberErrorMessage = this.$t(caseNumberResult).toString();
            } else {
                this.isValidCgtCaseNumber = true;
                this.companyRegistrationNumberErrorMessage = "";
            }
        }
        this.updateFormValidity();
    }

    private validateCompanyRegistrationNumber() {
        const newCompanyRegNumber = this.formData.companyRegNumber;

        const requiredResult = this.cgtFormService.checkRequired(newCompanyRegNumber);
        if (requiredResult !== true) {
            this.isValidCompanyRegistrationNumber = false;
            this.companyRegistrationNumberErrorMessage = this.$t(requiredResult).toString();
        } else {
            const companyRegistrationNumberResult = this.cgtFormService.checkCompanyRegistrationNumber(newCompanyRegNumber);
            if (companyRegistrationNumberResult !== true) {
                this.isValidCompanyRegistrationNumber = false;
                this.companyRegistrationNumberErrorMessage = this.$t(companyRegistrationNumberResult).toString();
            } else {
                this.isValidCompanyRegistrationNumber = true;
                this.companyRegistrationNumberErrorMessage = "";
            }
        }

        // Delete petitioner name and address only when a valid company registration number is changed to another valid company registration number.
        if (newCompanyRegNumber.length === 12 && this.formerCompanyRegNumber === '') {
            this.formerCompanyRegNumber = newCompanyRegNumber;
        }
        if (newCompanyRegNumber.length === 12 && this.formerCompanyRegNumber !== newCompanyRegNumber) {
            this.formerCompanyRegNumber = newCompanyRegNumber;
            this.formData.name = "";
        }

        this.updateFormValidity();
    }

    private validateCompanyName() {
        const requiredResult = this.cgtFormService.checkRequired(this.formData.name);
        if (requiredResult !== true) {
            this.isValidCompanyName = false;
            this.companyNameErrorMessage = this.$t(requiredResult).toString();
        } else {
            const forbiddenCharacterResult = this.cgtFormService.checkForbiddenCharacter(this.formData.name);
            if (forbiddenCharacterResult !== true) {
                this.isValidCompanyName = false;
                this.companyNameErrorMessage = this.$t(forbiddenCharacterResult).toString();
            } else {
                this.isValidCompanyName = true;
                this.companyNameErrorMessage = "";
            }
        }

        this.updateFormValidity();
    }

    private validatePetitionerCompanyRegistrationNumber() {
        const newCompanyRegNumber = this.formData.petitioner.companyRegNumber;

        const requiredResult = this.cgtFormService.checkRequired(newCompanyRegNumber);
        if (requiredResult !== true) {
            this.isValidPetitionerCompanyRegistrationNumber = false;
            this.petitionerCompanyRegistrationNumberErrorMessage = this.$t(requiredResult).toString();
        } else {
            const companyRegistrationNumberResult = this.cgtFormService.checkCompanyRegistrationNumber(newCompanyRegNumber);
            if (companyRegistrationNumberResult !== true) {
                this.isValidPetitionerCompanyRegistrationNumber = false;
                this.petitionerCompanyRegistrationNumberErrorMessage = this.$t(companyRegistrationNumberResult).toString();
            } else {
                this.isValidPetitionerCompanyRegistrationNumber = true;
                this.petitionerCompanyRegistrationNumberErrorMessage = "";
            }
        }

        // Delete petitioner name and address only when a valid company registration number is changed to another valid company registration number.
        if (newCompanyRegNumber.length === 12 && this.formerPetitionerCompanyRegNumber === '') {
            this.formerPetitionerCompanyRegNumber = newCompanyRegNumber;
        }
        if (newCompanyRegNumber.length === 12 && this.formerPetitionerCompanyRegNumber !== newCompanyRegNumber) {
            this.formData.petitioner.name = "";
            this.formData.petitioner.address = new Address();
            this.formerPetitionerCompanyRegNumber = newCompanyRegNumber;
        }

        this.updateFormValidity();
    }

    private validatePetitionerRegistrationNumber() {
        const result = this.cgtFormService.checkForbiddenCharacter(this.formData.petitioner.regNumber);
        if (result !== true) {
            this.isValidPetitionerRegistrationNumber = false;
            this.petitionerRegistrationNumberErrorMessage = this.$t(result).toString();
        } else {
            this.isValidPetitionerRegistrationNumber = true;
            this.petitionerRegistrationNumberErrorMessage = "";
        }

        this.updateFormValidity();
    }

    private validatePetitionerRecordOffice() {
        const result = this.cgtFormService.checkForbiddenCharacter(this.formData.petitioner.recordOffice);
        if (result !== true) {
            this.isValidPetitionerRecordOffice = false;
            this.petitionerRecordOfficeErrorMessage = this.$t(result).toString();
        } else {
            this.isValidPetitionerRecordOffice = true;
            this.petitionerRecordOfficeErrorMessage = "";
        }

        this.updateFormValidity();
    }

    private validatePetitionerName() {
        const requiredResult = this.cgtFormService.checkRequired(this.formData.petitioner.name);
        if (requiredResult !== true) {
            this.isValidPetitionerName = false;
            this.petitionerNameErrorMessage = this.$t(requiredResult).toString();
        } else {
            const forbiddenCharacterResult = this.cgtFormService.checkForbiddenCharacter(this.formData.petitioner.name);
            if (forbiddenCharacterResult !== true) {
                this.isValidPetitionerName = false;
                this.petitionerNameErrorMessage = this.$t(forbiddenCharacterResult).toString();
            } else {
                this.isValidPetitionerName = true;
                this.petitionerNameErrorMessage = "";
            }
        }

        this.updateFormValidity();
    }

    private validateLegalRepresentativeName() {
        const requiredResult = this.cgtFormService.checkRequired(this.formData.legalRepresentative.name);
        if (requiredResult !== true) {
            this.isValidLegalRepresentativeName = false;
            this.legalRepresentativeNameErrorMessage = this.$t(requiredResult).toString();
        } else {
            const forbiddenCharacterResult = this.cgtFormService.checkForbiddenCharacter(this.formData.legalRepresentative.name);
            if (forbiddenCharacterResult !== true) {
                this.isValidLegalRepresentativeName = false;
                this.legalRepresentativeNameErrorMessage = this.$t(forbiddenCharacterResult).toString();
            } else {
                this.isValidLegalRepresentativeName = true;
                this.legalRepresentativeNameErrorMessage = "";
            }
        }

        this.updateFormValidity();
    }

    private validateLegalRepresentativeAssociationName() {
        const requiredResult = this.cgtFormService.checkRequired(this.formData.legalRepresentative.associationName);
        if (requiredResult !== true) {
            this.isValidLegalRepresentativeAssociationName = false;
            this.legalRepresentativeAssociationNameErrorMessage = this.$t(requiredResult).toString();
        } else {
            const forbiddenCharacterResult = this.cgtFormService.checkForbiddenCharacter(this.formData.legalRepresentative.associationName);
            if (forbiddenCharacterResult !== true) {
                this.isValidLegalRepresentativeAssociationName = false;
                this.legalRepresentativeAssociationNameErrorMessage = this.$t(forbiddenCharacterResult).toString();
            } else {
                this.isValidLegalRepresentativeAssociationName = true;
                this.legalRepresentativeAssociationNameErrorMessage = "";
            }
        }

        this.updateFormValidity();
    }

    private validateLegalRepresentativeAssociationNumber() {
        const requiredResult = this.cgtFormService.checkRequired(this.formData.legalRepresentative.associationNumber);
        if (requiredResult !== true) {
            this.isValidLegalRepresentativeAssociationNumber = false;
            this.legalRepresentativeAssociationNumberErrorMessage = this.$t(requiredResult).toString();
        } else {
            const forbiddenCharacterResult = this.cgtFormService.checkForbiddenCharacter(this.formData.legalRepresentative.associationNumber);
            if (forbiddenCharacterResult !== true) {
                this.isValidLegalRepresentativeAssociationNumber = false;
                this.legalRepresentativeAssociationNumberErrorMessage = this.$t(forbiddenCharacterResult).toString();
            } else {
                this.isValidLegalRepresentativeAssociationNumber = true;
                this.legalRepresentativeAssociationNumberErrorMessage = "";
            }
        }

        this.updateFormValidity();
    }

    private validateLegalRepresentativeEmail() {
        const requiredResult = this.cgtFormService.checkRequired(this.formData.legalRepresentative.email);
        if (requiredResult !== true) {
            this.isValidLegalRepresentativeEmail = false;
            this.legalRepresentativeEmailErrorMessage = this.$t(requiredResult).toString();
        } else {
            const emailResult = this.cgtFormService.checkEmail(this.formData.legalRepresentative.email);
            if (emailResult !== true) {
                this.isValidLegalRepresentativeEmail = false;
                this.legalRepresentativeEmailErrorMessage = this.$t(emailResult).toString();
            } else {
                this.isValidLegalRepresentativeEmail = true;
                this.legalRepresentativeEmailErrorMessage = "";
            }
        }

        this.updateFormValidity();
    }

    private validateLegalRepresentativePhoneNumber() {
        const result = this.cgtFormService.checkForbiddenCharacter(this.formData.legalRepresentative.phoneNumber);
        if (result !== true) {
            this.isValidLegalRepresentativePhoneNumber = false;
            this.legalRepresentativePhoneNumber = this.$t(result).toString();
        } else {
            this.isValidLegalRepresentativePhoneNumber = true;
            this.legalRepresentativePhoneNumber = "";
        }

        this.updateFormValidity();
    }

    private validatePetitionerEmail() {
        const requiredResult = this.cgtFormService.checkRequired(this.formData.petitioner.email);
        if (requiredResult !== true) {
            this.isValidPetitionerEmail = false;
            this.petitionerEmailErrorMessage = this.$t(requiredResult).toString();
        } else {
            const emailResult = this.cgtFormService.checkEmail(this.formData.petitioner.email);
            if (emailResult !== true) {
                this.isValidPetitionerEmail = false;
                this.petitionerEmailErrorMessage = this.$t(emailResult).toString();
            } else {
                this.isValidPetitionerEmail = true;
                this.petitionerEmailErrorMessage = "";
            }
        }

        this.updateFormValidity();
    }

    private validatePetitionerCaseNumber() {
        const result = this.cgtFormService.checkForbiddenCharacter(this.formData.petitioner.caseNumber);
        if (result !== true) {
            this.isValidPetitionerCaseNumber = false;
            this.petitionerCaseNumberErrorMessage = this.$t(result).toString();
        } else {
            this.isValidPetitionerCaseNumber = true;
            this.petitionerCaseNumberErrorMessage = "";
        }

        this.updateFormValidity();
    }

    private validatePetitionerAdministrator() {
        const result = this.cgtFormService.checkForbiddenCharacter(this.formData.petitioner.administrator);
        if (result !== true) {
            this.isValidPetitionerAdministrator = false;
            this.petitionerAdministratorErrorMessage = this.$t(result).toString();
        } else {
            this.isValidPetitionerAdministrator = true;
            this.petitionerAdministratorErrorMessage = "";
        }

        this.updateFormValidity();
    }

    private validatePetitionerAdministratorPhoneNumber() {
        const result = this.cgtFormService.checkForbiddenCharacter(this.formData.petitioner.administratorPhoneNumber);
        if (result !== true) {
            this.isValidPetitionerAdministratorPhoneNumber = false;
            this.petitionerAdministratorPhoneNumberErrorMessage = this.$t(result).toString();
        } else {
            this.isValidPetitionerAdministratorPhoneNumber = true;
            this.petitionerAdministratorPhoneNumberErrorMessage = "";
        }

        this.updateFormValidity();
    }

    private validateStatement() {
        const result = this.cgtFormService.checkMultiline(this.formData.statement);
        if (result !== true) {
            this.isValidStatement = false;
            this.statementErrorMessage = this.$t(result).toString();
        } else {
            this.isValidStatement = true;
            this.statementErrorMessage = "";
        }

        this.updateFormValidity();
    }

    private petitionerAddressValidation(isValid: boolean) {
        this.isValidPetitionerAddress = isValid;
        this.petitionerAddressValidationNeeded = false;
        this.updateFormValidity();
    }

    private legalRepresentativeAddressValidation(isValid: boolean) {
        this.isValidLegalRepresentativeAddress = isValid;
        this.legalRepresentativeAddressValidationNeeded = false;
        this.updateFormValidity();
    }
    //endregion

    private isLegalRepresentativeSelected() {
        return this.selectedForm === AdditionalCgtForm.JOGI;
    }

    private isPetitionerRoleCompany() {
        return this.formData.petitioner.role === PetitionerRole.ELJARAS_ALA_VONT_CEG;
    }

    private xmlGenerationButtonClicked() {
        this.validateCgtCaseNumber();
        this.validateCompanyRegistrationNumber();
        this.validateCompanyName();
        this.validatePetitionerCompanyRegistrationNumber();
        this.validatePetitionerRegistrationNumber();
        this.validatePetitionerRecordOffice();
        this.validatePetitionerName();
        this.validateLegalRepresentativeName();
        this.validateLegalRepresentativeAssociationName();
        this.validateLegalRepresentativeAssociationNumber();
        this.validateLegalRepresentativeEmail();
        this.validateLegalRepresentativePhoneNumber();
        this.validatePetitionerEmail();
        this.validatePetitionerCaseNumber();
        this.validatePetitionerAdministrator();
        this.validatePetitionerAdministratorPhoneNumber();
        this.validateStatement();

        // Trigger an address validation.
        this.petitionerAddressValidationNeeded = true;
        this.legalRepresentativeAddressValidationNeeded = true;

        this.updateFormValidity();
        if (this.isValidForm) {
            this.createXml();
        }
    }

    private createXml() {
        this.prepareFormData();
        const xml = this.cgtFormService.generate(this.formData);
        xml.then(value => {
            const url = window.URL.createObjectURL(
                new Blob([value.data], {
                    type: 'text/xml; charset=utf-8',
                })
            );
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'cgt_urlap_kitoltve.xml');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        });
    }

    private onCompanyRegistrationNumberSubmit() {
        if (this.formData.companyRegNumber) {
            this.validateCompanyRegistrationNumber();

            if (this.isValidCompanyRegistrationNumber) {
                this.isCaptchaVisibleForName = true;
            }
        }
    }

    private onPetitionerCompanyRegistrationNumberSubmit() {
        if (this.formData.petitioner.companyRegNumber) {
            this.validatePetitionerCompanyRegistrationNumber();

            if (this.isValidPetitionerCompanyRegistrationNumber) {
                this.isCaptchaVisibleForData = true;
            }
        }
    }

    private onChangeIsCompany() {
        if (this.isCompany) {
            this.formData.petitioner.regNumber = '';
            this.formData.petitioner.recordOffice = '';
        } else {
            this.formData.petitioner.companyRegNumber = '';
        }
        this.deletePetitionersFilledFields();
        this.updateFormValidity();
    }

    private onChangeSelectedFormValue() {
        if (this.isLegalRepresentativeSelected()) {
            // If petitioner is clicked, then clear legal representative.
            this.formData.legalRepresentative = new LegalRepresentative();
            this.selectedForm = AdditionalCgtForm.BENYUJTO;
        } else {
            // If legal representative is clicked, then clear petitioner.
            this.formData.petitioner.email = '';
            this.formData.petitioner.administratorPhoneNumber = '';
            this.formData.petitioner.administrator = '';
            this.formData.petitioner.caseNumber = '';
            this.selectedForm = AdditionalCgtForm.JOGI;
        }
        this.updateFormValidity();
    }

    private fetchCompanyName(captchaToken: string) {
        this.isCaptchaVisibleForName = false;
        this.cgtFormService.retrieveCompanyName(this.formData.companyRegNumber, captchaToken).then(data => {
                this.formData.name = data.data.name;
            }
        )
    }

    private fetchCompanyData(captchaToken: string) {
        this.isCaptchaVisibleForData = false;
        this.deletePetitionersFilledFields();
        this.cgtFormService.retrieveCompanyNameAndAddress(this.formData.petitioner.companyRegNumber, captchaToken).then(data => {
                this.formData.petitioner.name = data.data.name;
                this.formData.petitioner.address = data.data.address;
            }
        )
    }

    private deletePetitionersFilledFields() {
        this.formData.petitioner.name = '';
        this.formData.petitioner.address = new Address();
    }

    private prepareFormData() {
        if (this.isNewCgtCase) {
            this.formData.cgtCaseNumber = '';
        }
        if (!this.isCompany) {
            this.formData.petitioner.companyRegNumber = '';
        }
    }

    //region Computed properties
    get isFillNameByCompanyRegistrationNumberActive() {
        return this.cgtFormService.checkCompanyRegistrationNumber(this.formData.companyRegNumber) === true;
    }

    get isFillDataByCompanyRegistrationNumberActive() {
        return this.cgtFormService.checkCompanyRegistrationNumber(this.formData.petitioner.companyRegNumber) === true;
    }
    //endregion

    @Watch('formData.petitioner.role')
    public petitionerRoleChanged() {
        if (this.isPetitionerRoleCompany()) {
            this.isCompany = true;
        }
    }
}
