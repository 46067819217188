export default class Address {
    public countryCode: string = '';
    public postalCode: string = '';
    public city: string = '';
    public district: string = '';
    public areaName: string = '';
    public areaType: string = '';
    public streetNumber: string = '';
    public building: string = '';
    public stairway: string = '';
    public floor: string = '';
    public door: string = '';
}
