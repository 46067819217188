<template>
  <div :id="captchaId" class="g-recaptcha"></div>
</template>

<script>

import {CaptchaControllerApi} from "@/client";
import http from "@/plugins/http";

export default {
    data() {
        return {
            captchaService: new CaptchaControllerApi(undefined, process.env.VUE_APP_API_BASE_URL, http),
            widgetId: 0,
        };
    },
    props: ['captchaId'],
    methods: {
    reset() {
      window.grecaptcha.reset(this.widgetId);
    },
    render() {
      const id = this.captchaId;
        this.captchaService.getSiteKey()
          .then((response) => {
            this.widgetId = window.grecaptcha.render(id, {
              sitekey: response.data,
              callback: (response2) => {
                this.$emit('verify', response2);
                this.reset();
              },
            });
          });
    },
  },
  mounted() {
    this.render();
  },
};
</script>
