export const countries: { key: string; name: string }[] = [
    {key: 'AD', name: 'Andorra'},
    {key: 'AE', name: 'Egyesült Arab Emírségek'},
    {key: 'AF', name: 'Afganisztán'},
    {key: 'AG', name: 'Antigua és Barbuda'},
    {key: 'AI', name: 'Anguilla'},
    {key: 'AL', name: 'Albánia'},
    {key: 'AM', name: 'Örményország'},
    {key: 'AO', name: 'Angola'},
    {key: 'AQ', name: 'Antarktisz'},
    {key: 'AR', name: 'Argentína'},
    {key: 'AS', name: 'Amerikai Szamoa'},
    {key: 'AT', name: 'Ausztria'},
    {key: 'AU', name: 'Ausztrália'},
    {key: 'AW', name: 'Aruba'},
    {key: 'AX', name: 'Aland'},
    {key: 'AZ', name: 'Azerbajdzsán'},
    {key: 'BA', name: 'Bosznia és Hercegovina'},
    {key: 'BB', name: 'Barbados'},
    {key: 'BD', name: 'Banglades'},
    {key: 'BE', name: 'Belgium'},
    {key: 'BF', name: 'Burkina Faso'},
    {key: 'BG', name: 'Bulgária'},
    {key: 'BH', name: 'Bahrein'},
    {key: 'BI', name: 'Burundi'},
    {key: 'BJ', name: 'Benin'},
    {key: 'BL', name: 'Saint-Barthélemy'},
    {key: 'BM', name: 'Bermuda'},
    {key: 'BN', name: 'Brunei Darussalam Állam'},
    {key: 'BO', name: 'Bolívia'},
    {key: 'BQ', name: 'Karibi Hollandia ( Bonaire,  Saba,  Sint Eustatius)'},
    {key: 'BR', name: 'Brazília'},
    {key: 'BS', name: 'Bahama-szigetek'},
    {key: 'BT', name: 'Bhután'},
    {key: 'BV', name: 'Bouvet-sziget'},
    {key: 'BW', name: 'Botswana'},
    {key: 'BY', name: 'Belarusz'},
    {key: 'BZ', name: 'Belize'},
    {key: 'CA', name: 'Kanada'},
    {key: 'CC', name: 'Kókusz-szigetek'},
    {key: 'CD', name: 'Kongói Demokratikus Köztársaság'},
    {key: 'CF', name: 'Közép-afrikai Köztársaság'},
    {key: 'CG', name: 'Kongó'},
    {key: 'CH', name: 'Svájc'},
    {key: 'CI', name: 'Elefántcsontpart'},
    {key: 'CK', name: 'Cook-szigetek'},
    {key: 'CL', name: 'Chile'},
    {key: 'CM', name: 'Kamerun'},
    {key: 'CN', name: 'Kína'},
    {key: 'CO', name: 'Kolumbia'},
    {key: 'CR', name: 'Costa Rica'},
    {key: 'CU', name: 'Kuba'},
    {key: 'CV', name: 'Zöld-foki-szigetek'},
    {key: 'CW', name: 'Curaçao'},
    {key: 'CX', name: 'Karácsony-sziget'},
    {key: 'CY', name: 'Ciprus'},
    {key: 'CZ', name: 'Cseh Köztársaság'},
    {key: 'CS', name: 'Szerbia és Montenegró'},
    {key: 'DE', name: 'Németország'},
    {key: 'DJ', name: 'Dzsibuti'},
    {key: 'DK', name: 'Dánia'},
    {key: 'DM', name: 'Dominika'},
    {key: 'DO', name: 'Dominikai Köztársaság'},
    {key: 'DZ', name: 'Algéria'},
    {key: 'EC', name: 'Ecuador'},
    {key: 'EE', name: 'Észtország'},
    {key: 'EG', name: 'Egyiptom'},
    {key: 'EH', name: 'Nyugat-Szahara'},
    {key: 'ER', name: 'Eritrea'},
    {key: 'ES', name: 'Spanyolország'},
    {key: 'ET', name: 'Etiópia'},
    {key: 'FI', name: 'Finnország'},
    {key: 'FJ', name: 'Fidji'},
    {key: 'FK', name: 'Falkland-szigetek (Malvinas)'},
    {key: 'FM', name: 'Mikronéziai Szövetségi Államok'},
    {key: 'FO', name: 'Feröer-szigetek'},
    {key: 'FR', name: 'Franciaország'},
    {key: 'GA', name: 'Gabon'},
    {key: 'GB', name: 'Egyesült Királyság'},
    {key: 'GD', name: 'Grenada'},
    {key: 'GE', name: 'Grúzia'},
    {key: 'GF', name: 'Francia Guyana Francia Guyana'},
    {key: 'GG', name: 'Guernsey Bailiffség'},
    {key: 'GH', name: 'Ghána'},
    {key: 'GI', name: 'Gibraltár'},
    {key: 'GL', name: 'Grönland'},
    {key: 'GM', name: 'Gambia'},
    {key: 'GN', name: 'Guinea'},
    {key: 'GP', name: 'Guadeloupe'},
    {key: 'GQ', name: 'Egyenlítői-Guinea'},
    {key: 'GR', name: 'Görögország'},
    {key: 'GS', name: 'Dél-Georgia és a Déli-Sandwichszigetek'},
    {key: 'GT', name: 'Guatemala'},
    {key: 'GU', name: 'Guam'},
    {key: 'GW', name: 'Bissau-Guinea'},
    {key: 'GY', name: 'Guyana'},
    {key: 'HK', name: 'Hongkong'},
    {key: 'HM', name: 'Heard-sziget és McDonald-szigetek'},
    {key: 'HN', name: 'Honduras'},
    {key: 'HR', name: 'Horvátország'},
    {key: 'HT', name: 'Haiti'},
    {key: 'HU', name: 'Magyarország'},
    {key: 'ID', name: 'Indonézia'},
    {key: 'IE', name: 'Írország'},
    {key: 'IL', name: 'Izrael'},
    {key: 'IM', name: 'Man'},
    {key: 'IN', name: 'India'},
    {key: 'IO', name: 'Brit Indiai-óceáni Terület'},
    {key: 'IQ', name: 'Irak'},
    {key: 'IR', name: 'Iráni Iszlám Köztársaság'},
    {key: 'IS', name: 'Izland'},
    {key: 'IT', name: 'Olaszország'},
    {key: 'JE', name: 'Jersey Bailiffség'},
    {key: 'JM', name: 'Jamaica'},
    {key: 'JO', name: 'Jordánia'},
    {key: 'JP', name: 'Japán'},
    {key: 'KE', name: 'Kenya'},
    {key: 'KG', name: 'Kirgiz Köztársaság'},
    {key: 'KH', name: 'Kambodzsa'},
    {key: 'KI', name: 'Kiribati'},
    {key: 'KM', name: 'Comore-szigetek'},
    {key: 'KN', name: 'Saint Kitts és Nevis'},
    {key: 'KP', name: 'Koreai Népi Demokratikus Köztársaság'},
    {key: 'KR', name: 'Koreai Köztársaság'},
    {key: 'KW', name: 'Kuvait'},
    {key: 'KY', name: 'Kajmán-szigetek'},
    {key: 'KZ', name: 'Kazahsztán'},
    {key: 'LA', name: 'Laoszi Népi Demokratikus Köztársaság'},
    {key: 'LB', name: 'Libanon'},
    {key: 'LC', name: 'Saint Lucia'},
    {key: 'LI', name: 'Liechtenstein'},
    {key: 'LK', name: 'Srí Lanka'},
    {key: 'LR', name: 'Libéria'},
    {key: 'LS', name: 'Lesotho'},
    {key: 'LT', name: 'Litvánia'},
    {key: 'LU', name: 'Luxemburg'},
    {key: 'LV', name: 'Lettország'},
    {key: 'LY', name: 'Líbiai Arab Szocialista Népi Közösség'},
    {key: 'MA', name: 'Marokkó'},
    {key: 'MC', name: 'Monaco'},
    {key: 'MD', name: 'Moldovai Köztársaság'},
    {key: 'ME', name: 'Montenegró'},
    {key: 'MF', name: 'Saint-Martin'},
    {key: 'MG', name: 'Madagaszkár'},
    {key: 'MH', name: 'Marshall-szigetek'},
    {key: 'MK', name: 'Macedónia Volt Jugoszláv Köztársaság'},
    {key: 'ML', name: 'Mali'},
    {key: 'MM', name: 'Mianmar'},
    {key: 'MN', name: 'Mongólia'},
    {key: 'MO', name: 'Makao'},
    {key: 'MP', name: 'Észak-Marianna-szigetek'},
    {key: 'MQ', name: 'Martinique'},
    {key: 'MR', name: 'Mauritánia'},
    {key: 'MS', name: 'Montserrat'},
    {key: 'MT', name: 'Málta'},
    {key: 'MU', name: 'Mauritius'},
    {key: 'MV', name: 'Maldív-szigetek'},
    {key: 'MW', name: 'Malawi'},
    {key: 'MX', name: 'Mexikó'},
    {key: 'MY', name: 'Malajzia'},
    {key: 'MZ', name: 'Mozambik'},
    {key: 'NA', name: 'Namíbia'},
    {key: 'NC', name: 'Új-Kaledónia'},
    {key: 'NE', name: 'Niger'},
    {key: 'NF', name: 'Norfolk-sziget'},
    {key: 'NG', name: 'Nigéria'},
    {key: 'NI', name: 'Nicaragua'},
    {key: 'NL', name: 'Hollandia'},
    {key: 'NO', name: 'Norvégia'},
    {key: 'NP', name: 'Nepál'},
    {key: 'NR', name: 'Nauru'},
    {key: 'NU', name: 'Niue'},
    {key: 'NZ', name: 'Új-Zéland'},
    {key: 'OM', name: 'Omán'},
    {key: 'PA', name: 'Panama'},
    {key: 'PE', name: 'Peru'},
    {key: 'PF', name: 'Francia Polinézia'},
    {key: 'PG', name: 'Pápua Új-Guinea'},
    {key: 'PH', name: 'Fülöp-szigetek'},
    {key: 'PK', name: 'Pakisztán'},
    {key: 'PL', name: 'Lengyelország'},
    {key: 'PM', name: 'Saint Pierre és Miquelon'},
    {key: 'PN', name: 'Pitcairn-szigetek'},
    {key: 'PR', name: 'Puerto Rico'},
    {key: 'PS', name: 'Megszállt Palesztin terület'},
    {key: 'PT', name: 'Portugália'},
    {key: 'PW', name: 'Palau'},
    {key: 'PY', name: 'Paraguay'},
    {key: 'QA', name: 'Katar'},
    {key: 'RE', name: 'Réunion'},
    {key: 'RO', name: 'Románia'},
    {key: 'RS', name: 'Szerbia'},
    {key: 'RU', name: 'Orosz Föderáció'},
    {key: 'RW', name: 'Ruanda'},
    {key: 'SA', name: 'Szaúd-Arábia'},
    {key: 'SB', name: 'Salamon-szigetek'},
    {key: 'SC', name: 'Seychelle-szigetek'},
    {key: 'SD', name: 'Szudán'},
    {key: 'SE', name: 'Svédország'},
    {key: 'SG', name: 'Szingapúr'},
    {key: 'SH', name: 'Szent Ilona'},
    {key: 'SI', name: 'Szlovénia'},
    {key: 'SJ', name: 'Spitzbergák és Jan Mayen-sziget'},
    {key: 'SK', name: 'Szlovákia'},
    {key: 'SL', name: 'Sierra Leone'},
    {key: 'SM', name: 'San Marino'},
    {key: 'SN', name: 'Szenegál'},
    {key: 'SO', name: 'Szomália'},
    {key: 'SR', name: 'Suriname'},
    {key: 'SS', name: 'Dél-Szudán'},
    {key: 'ST', name: 'Sao Tomé és Príncipe'},
    {key: 'SV', name: 'Salvador'},
    {key: 'SX', name: 'Sint Maarten'},
    {key: 'SY', name: 'Szíriai Arab Köztársaság'},
    {key: 'SZ', name: 'Szváziföld'},
    {key: 'TC', name: 'Turks- és Caicos-szigetek'},
    {key: 'TD', name: 'Csád'},
    {key: 'TF', name: 'Francia Déli Területek'},
    {key: 'TG', name: 'Togo'},
    {key: 'TH', name: 'Thaiföld'},
    {key: 'TJ', name: 'Tádzsikisztán'},
    {key: 'TK', name: 'Tokelau'},
    {key: 'TL', name: 'Timor-Leste'},
    {key: 'TM', name: 'Türkmenisztán'},
    {key: 'TN', name: 'Tunézia'},
    {key: 'TO', name: 'Tonga'},
    {key: 'TR', name: 'Törökország'},
    {key: 'TT', name: 'Trinidad és Tobago'},
    {key: 'TV', name: 'Tuvalu'},
    {key: 'TW', name: 'Tajvan'},
    {key: 'TZ', name: 'Tanzániai Egyesült Köztársaság'},
    {key: 'UA', name: 'Ukrajna'},
    {key: 'UG', name: 'Uganda'},
    {key: 'UM', name: 'Az Amerikai Egyesült Államok Külső Szigetei'},
    {key: 'US', name: 'Amerikai Egyesült Államok'},
    {key: 'UY', name: 'Uruguay'},
    {key: 'UZ', name: 'Üzbegisztán'},
    {key: 'VA', name: 'Apostoli Szentszék (Vatikán)'},
    {key: 'VC', name: 'Saint Vincent és Grenadineszigetek'},
    {key: 'VE', name: 'Venezuela'},
    {key: 'VG', name: 'Brit Virgin-szigetek'},
    {key: 'VI', name: 'Amerikai Virgin-szigetek'},
    {key: 'VN', name: 'Vietnam'},
    {key: 'VU', name: 'Vanuatu'},
    {key: 'WF', name: 'Wallis és Futuna'},
    {key: 'WS', name: 'Szamoa'},
    {key: 'XK', name: 'Koszovó'},
    {key: 'YE', name: 'Jemen'},
    {key: 'YT', name: 'Mayotte'},
    {key: 'ZA', name: 'Dél-Afrika'},
    {key: 'ZM', name: 'Zambia'},
    {key: 'ZW', name: 'Zimbabwe'}
]
