import Address from "./address";

export default class LegalRepresentative {
    public name: string = '';
    public address: Address = new Address();
    public email: string = '';
    public phoneNumber: string = '';
    public associationName: string = '';
    public associationNumber: string = '';
}
